import * as React from "react";

import symbols from "../../styles/thirdparty/salesforce/icons/utility-sprite/svg/symbols.svg";

const statusPicklist = [
  {
    step: 1,
    label: "Review",
    value: "Review",
  },
  {
    step: 2,
    label: "Submit for Verification",
    value: "Submit for Verification",
  },
  {
    step: 3,
    label: "In Approval Process",
    value: "In Approval Process",
  },
  {
    step: 4,
    label: "Completed",
    value: "Completed",
  },
];

const statusOnScreen = function (status) {
  if (status === "Registered" || status === "Review") {
    return "Review";
  } else if (
    status === "Request Additional Information and Documents" ||
    status === "Verify Counterparty"
  ) {
    return "Submit for Verification";
  } else if (status === "In Approval Process") {
    return "In Approval Process";
  } else if (status === "Completed") {
    return "Completed";
  } else if (status === "Rejected") {
    return "Rejected";
  }
};

function styleStatus(status, currentStatus) {
  const statusIdx = statusPicklist.findIndex((item) => item.value === status);
  const currentStatusIdx = statusPicklist.findIndex(
    (item) => item.value === statusOnScreen(currentStatus)
  );

  if (statusPicklist[statusIdx] && statusPicklist[currentStatusIdx]) {
    if (statusPicklist[statusIdx].step < statusPicklist[currentStatusIdx].step)
      return "slds-path__item slds-is-complete";
    else if (
      statusPicklist[statusIdx].step === statusPicklist[currentStatusIdx].step
    )
      return "slds-path__item slds-is-current slds-is-active";
    else return "slds-path__item slds-is-incomplete";
  } else {
    return "slds-path__item slds-is-incomplete";
  }
}

export default function ApprovalStatus({ currentStatus }) {
  return (
    <div className="slds-path">
      <div className="slds-grid slds-path__track">
        <div className="slds-grid slds-path__scroller-container">
          <div className="slds-path__scroller">
            <div className="slds-path__scroller_inner">
              <ul
                className="slds-path__nav"
                role="listbox"
                aria-orientation="horizontal"
                aria-labelledby="slds-path__stage-name"
              >
                {statusPicklist.map((item) => (
                  <li
                    className={styleStatus(item.value, currentStatus)}
                    key={item.step}
                    role="presentation"
                  >
                    <div
                      className="slds-path__link"
                      aria-selected="true"
                      role="option"
                    >
                      <span className="slds-path__stage">
                        <svg
                          className="slds-icon slds-icon_x-small"
                          aria-hidden="true"
                        >
                          <use xlinkHref={`${symbols}#check`}></use>
                        </svg>
                      </span>
                      <span className="slds-path__title">{item.label}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
